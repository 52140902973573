import React, { useEffect, useState } from 'react';
import '../pages/tournaments/styles/TournamentDetails.css'
import { GrGallery, GrSubtract } from 'react-icons/gr';
import { ImLink } from 'react-icons/im';
import { Link, useParams, useNavigate} from 'react-router-dom';
import moment from 'moment';
import {auth, database } from '../config/firebaseConfig';
import { ref, update , get } from 'firebase/database';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import Swal from 'sweetalert2';
import axios from 'axios';

const SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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';
const BACKEND_URL = "https://cheda-gaming-api.onrender.com";

function TournamentDetailsSection() {

  const [tournament, setTournament] = useState({});
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [host, setHost] = useState({});
  const { tournamentURL } = useParams();
  const navigate = useNavigate();

  const [showMessage, setShowMessage] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const isLoggedIn = localStorage.getItem("isAuthenticated");
  const currentUserId = auth?.currentUser?.uid;

    // Fetch current user data
  useEffect(() => {
      if (!currentUserId) {
        console.error("User not authenticated");
        navigate('/login'); // Redirect unauthenticated users to login
        return;
      }
  
      const fetchUserData = async () => {
        try {
          const userRef = ref(database, `Users/${currentUserId}`);
          const snapshot = await get(userRef);
  
          if (snapshot.exists()) {
            setUser(snapshot.val());
          } else {
            console.error("User data not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
  
      fetchUserData();
  }, [currentUserId, navigate]);

  useEffect(() => {
    if (!tournamentURL) {
      console.error("Invalid tournament URL");
      navigate('/tournaments'); // Redirect if URL is missing
      return;
    }

    const fetchTournamentData = async () => {
      try {
        const tournamentsRef = ref(database, 'HostedTournaments');
        const snapshot = await get(tournamentsRef);

        if (snapshot.exists()) {
          const tournamentsData = snapshot.val();
          // Find tournament by matching tournamentURL
          const matchedTournamentKey = Object.keys(tournamentsData).find(
            key => tournamentsData[key].tournamentURL === tournamentURL
          );

          if (matchedTournamentKey) {
            setTournament(tournamentsData[matchedTournamentKey]);
          } else {
            console.error("Tournament not found");
            navigate('/tournaments'); 
          }
        } else {
          console.error("No tournaments found.");
          navigate('/tournaments'); 
        }
      } catch (error) {
        console.error("Error fetching tournament:", error);
        navigate('/tournaments');
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentData();
  }, [tournamentURL, navigate]);

  const handleFlutterPayment = useFlutterwave({
    public_key: 'FLWPUBK-9921941606bacc3c90ea6cc78cd57d10-X',
    tx_ref: Date.now(),
    amount: parseFloat(tournament?.fee || 0), 
    currency: 'KES', 
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: user?.email || '',
      phone_number: user?.phoneNumber || '',
      name: `${user?.firstName} ${user.lastName}` || '',
    },
  });

  const handleFreeTournament = async () => {
    try {
      const tournamentRef = ref(database, `HostedTournaments/${tournament.tournamentId}`);
      const snapshot = await get(tournamentRef);
  
      if (snapshot.exists()) {
        const tournamentData = snapshot.val();
        // const updatedParticipants = Array.isArray(tournamentData.participants)
        //   ? [...tournamentData.participants, currentUserId]
        //   : [currentUserId];

          const gamerData  = {
            to: user?.email,
            userName: user?.username,
            tournamentName: tournamentData?.tournamentName
          }
          
        // Fetch host data
        const userRef = ref(database, `Users/${tournamentData.host}`);
        const userSnapshot = await get(userRef);
  
        let hostData;
        if (userSnapshot.exists()) {
          const host = userSnapshot.val();
          hostData = {
            to: host?.email,
            userName: host?.username,
            tournamentName: tournamentData?.tournamentName,
          };
        } else {
          console.error('Host data not found');
        }
  
        // await update(tournamentRef, { participants: updatedParticipants });
        
        await handleJoinTournament();
        
        navigate('/user-dashboard');
  
        // Send Slack notification for free tournaments
        await sendSlackNotification(tournamentData?.tournamentName, user?.username, user?.email, user?.phone, true);
        await sendGamerPaymentEmail(gamerData);
        await sendHostPaymentEmail(hostData);
  
        console.log("Successfully joined the free tournament!");
      } else {
        console.error("Tournament data not found.");
      }
    } catch (error) {
      console.error("Error updating tournament participants:", error);
    }
  };
  

  const handlePaymentSuccess = async (response) => {
    console.log('Payment successful:', response);

    try {
      const tournamentRef = ref(database, `HostedTournaments/${tournament.tournamentId}`);
      const snapshot = await get(tournamentRef);

      if (snapshot.exists()) {
        const tournamentData = snapshot.val();
        // const updatedParticipants = Array.isArray(tournamentData.participants)
        //   ? [...tournamentData.participants, currentUserId]
        //   : [currentUserId];
          
        const gamerData  = {
          to: user?.email,
          userName: user?.username,
          tournamentName: tournamentData?.tournamentName
        }
        
      // Fetch host data
      const userRef = ref(database, `Users/${tournamentData.host}`);
      const userSnapshot = await get(userRef);

      let hostData;
      if (userSnapshot.exists()) {
        const host = userSnapshot.val();
        hostData = {
          to: host?.email,
          userName: host?.username,
          tournamentName: tournamentData?.tournamentName,
        };
      } else {
        console.error('Host data not found');
      }

        // await update(tournamentRef, { participants: updatedParticipants });
        
        await handleJoinTournament();
        
        navigate('/user-dashboard');
        
        await sendSlackNotification(user?.username, user?.email, false);
        await sendGamerPaymentEmail(gamerData);
        await sendHostPaymentEmail(hostData);
        console.log("Tournament updated successfully with new participant.");
      } else {
        console.error("Tournament data not found.");
      }
    } catch (error) {
      console.error("Error updating tournament participants:", error);
    }
  };

  async function sendSlackNotification(tournamentName, username, email, phone, isFree = false) {
    const payload = {
      text: isFree
        ? `🎉 ${username} just joined your free tournament!\n*Tournament Name:* ${tournamentName}\n*Email:* ${email}\n*Phone Number:* ${phone}`
        : `Successful payment! 🎉 for \n*Tournament Name:* ${tournamentName} by\n${username}\n*Email:* ${email}\n*Phone Number:* ${phone}`,
    };
  
    try {
      const response = await fetch(SLACK_WEBHOOK_URL, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        console.error('Slack notification failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error sending Slack notification:', error);
    }
  }

  const sendGamerPaymentEmail = async (data) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/user/tournament-signup-email`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending tournament email:', error);
    }
  };

  const sendHostPaymentEmail = async (data) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/user/host-tournament-signup-email`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending tournament email:', error);
    }
  };

  // const handleJoinTournament = async () => {
  //   try {
  //     const tournamentRef = ref(database, `HostedTournaments/${tournament.tournamentId}`);
  //     const snapshot = await get(tournamentRef);
  
  //     if (snapshot.exists()) {
  //       const tournamentData = snapshot.val();
  
  //       // Add the new user to the participants list
  //       const updatedParticipants = Array.isArray(tournamentData.participants)
  //         ? [...tournamentData.participants, currentUserId]
  //         : [currentUserId];
  
  //       // Check if round1 exists, if not create it
  //       let round1Exists = tournamentData.rounds && tournamentData.rounds.round1;
  //       if (!round1Exists) {
  //         // Create round1 if not existing
  //         await update(tournamentRef, {
  //           rounds: {
  //             round1: {}
  //           }
  //         });
  //         round1Exists = true; // Now round1 exists
  //       }
  
  //       // Get the current matches in round1
  //       const round1Matches = tournamentData.rounds.round1 || {};
  
  //       // Find the match with the highest number
  //       const matchNumbers = Object.keys(round1Matches).map(match => parseInt(match.replace('match', ''), 10));
  //       const highestMatchNumber = Math.max(...matchNumbers, 0);
  
  //       let matchUpdated = false;
  
  //       // Loop through all matches in round1 to check if we can add the participant to an existing match
  //       for (let i = 1; i <= highestMatchNumber; i++) {
  //         const matchKey = `match${i}`;
  //         const match = round1Matches[matchKey];
  //         const participants = Object.keys(match);
  
  //         if (participants.length < 2) {
  //           // If there is space (one participant), add the new user
  //           const newMatch = { ...match };
  //           newMatch[currentUserId] = { score: "" }; // Add new user with no score
  
  //           // Update match in round1
  //           await update(tournamentRef, { [`rounds/round1/${matchKey}`]: newMatch });
  //           matchUpdated = true;
  //           break;
  //         }
  //       }
  
  //       // If no match with space was found, create a new match
  //       if (!matchUpdated) {
  //         const newMatchKey = `match${highestMatchNumber + 1}`;
  //         const newMatch = {
  //           [currentUserId]: { score: "" }
  //         };
  
  //         await update(tournamentRef, { [`rounds/round1/${newMatchKey}`]: newMatch });
  //       }
  
  //       // Calculate the total number of rounds and matches for future rounds
  //       const totalParticipants = updatedParticipants.length;
  //       const totalRounds = Math.ceil(Math.log2(totalParticipants)); // Calculate rounds based on the number of participants
  
  //       // Create the additional rounds (if any)
  //       let roundsToUpdate = { ...tournamentData.rounds };
  //       for (let i = 2; i <= totalRounds; i++) {
  //         const roundKey = `round${i}`;
  //         if (!roundsToUpdate[roundKey]) {
  //           // Initialize empty round (no participants yet)
  //           roundsToUpdate[roundKey] = {};
  
  //           // Calculate number of matches for this round based on number of players in the previous round
  //           const previousRoundParticipants = updatedParticipants.length / Math.pow(2, i - 1); // Halve participants for each round
  //           const numberOfMatches = previousRoundParticipants / 2;
  
  //           // Create matches in this round (without participants yet)
  //           for (let j = 1; j <= numberOfMatches; j++) {
  //             const matchKey = `match${j}`;
  //             roundsToUpdate[roundKey][matchKey] = {}; // Initialize empty match
  //           }
  //         }
  //       }
  
  //       // Update rounds and participants in a single update to avoid overwriting
  //       await update(tournamentRef, {
  //         rounds: roundsToUpdate,
  //         participants: updatedParticipants
  //       });
  
  //       console.log("User successfully joined the tournament and matched!");
  //     } else {
  //       console.error("Tournament not found.");
  //     }
  //   } catch (error) {
  //     console.error("Error joining tournament:", error);
  //   }
  // };

  const handleJoinTournament = async () => {
    try {
      const tournamentRef = ref(database, `HostedTournaments/${tournament.tournamentId}`);
      const snapshot = await get(tournamentRef);
  
      if (snapshot.exists()) {
        const tournamentData = snapshot.val();
  
        if (!currentUserId) {
          console.error("Invalid currentUserId.");
          return;
        }
  
        const updatedParticipants = Array.isArray(tournamentData.participants)
          ? [...tournamentData.participants, currentUserId]
          : [currentUserId];
  
        if (updatedParticipants.includes(undefined)) {
          console.error("Updated participants list contains undefined values.");
          return;
        }
  
        if (!tournamentData.rounds?.round1) {
          await update(tournamentRef, {
            'rounds/round1': {}, 
          });
        }
  
        const totalParticipants = updatedParticipants.length;
        const totalRounds = Math.ceil(Math.log2(totalParticipants));
  
        console.log(`Total number of rounds predicted: ${totalRounds}`);
  
        const roundsToUpdate = {};
  
        for (let i = 2; i <= totalRounds; i++) {
          const roundKey = `round${i}`;
          const previousRoundParticipants = Math.ceil(updatedParticipants.length / Math.pow(2, i - 1)); 
          const numberOfMatches = Math.ceil(previousRoundParticipants / 2);
  
          console.log(`Round ${i}: ${numberOfMatches} matches`);
  
          // Initialize matches for the round
          roundsToUpdate[`rounds/${roundKey}`] = {};
  
          for (let j = 1; j <= numberOfMatches; j++) {
            const matchKey = `match${j}`;
            roundsToUpdate[`rounds/${roundKey}`][matchKey] = {}; 
          }
        }
  
        const round1Matches = tournamentData.rounds.round1 || {};
        let matchUpdated = false;
  
        const matchNumbers = Object.keys(round1Matches).map((match) =>
          parseInt(match.replace('match', ''), 10)
        );
        const highestMatchNumber = Math.max(...matchNumbers, 0);
  
        for (let i = 1; i <= highestMatchNumber; i++) {
          const matchKey = `match${i}`;
          const match = round1Matches[matchKey];
          const participants = Object.keys(match);
  
          if (participants.length < 2) {
            const newMatch = { ...match };
            newMatch[currentUserId] = { score: "" };
  
            await update(tournamentRef, {
              [`rounds/round1/${matchKey}`]: newMatch,
            });
            matchUpdated = true;
            break;
          }
        }
  
        if (!matchUpdated) {
          const newMatchKey = `match${highestMatchNumber + 1}`;
          const newMatch = {
            [currentUserId]: { score: "" },
          };
  
          await update(tournamentRef, {
            [`rounds/round1/${newMatchKey}`]: newMatch,
          });
        }
  
        await update(tournamentRef, {
          participants: updatedParticipants,
        });
  
        if (Object.keys(roundsToUpdate).length > 0) {
          console.log("Rounds to update:", JSON.stringify(roundsToUpdate, null, 2)); 
          await update(tournamentRef, roundsToUpdate);
          console.log("Successfully updated the predicted rounds starting from round2!");
        }
  
        console.log("User successfully joined the tournament and round1 was updated!");
      } else {
        console.error("Tournament not found.");
      }
    } catch (error) {
      console.error("Error joining tournament:", error);
    }
  };
  
  if (loading) {
    return <p>Loading...</p>;
  }

  const handlePermalinkClick = () => {
    const permalink = window.location.href;
    navigator.clipboard.writeText(permalink)
      .then(() => console.log('Permalink copied to clipboard:', permalink))
      .catch((error) => console.error('Failed to copy permalink:', error));

    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  };

   

  return (
      <div className="tournament-info">
        <div className="main_tournament_details_container">
        <div className="first__section">

        <div className="container-wrap">
          <div className="item-container">
            <div className="more-like-this-item">
              <GrGallery className="icon_tournament" />
              <Link to='/tournaments' className="more-item-link">
                <span className="span-text">More like this</span>
              </Link>
            </div>
            <div><GrSubtract className="dash icon_tournament" /></div>
            <div className="permalink-item" onClick={handlePermalinkClick}>
              <ImLink className="icon_tournament" />
              <span className="span-text">Share tournament</span>
            </div><br/>
            {showMessage && <p className="message">Tournament link has been copied successfully</p>}
          </div>

          <div className="title-wrapper">            
            <h1>{tournament?.tournamentName}</h1>
            <div className="first-item-container">
              <h4 className="first-item-h">{moment(tournament.startDateTime).format("MMMM D, YYYY h:mm A")}</h4>
              <div><GrSubtract className="dash icon" /></div>
              <div className="location-container-wrapper">
                <h4 className="first-item-h">Location:</h4> 
                <p>{tournament.location}</p>
              </div>
              <div><GrSubtract className="dash icon" /></div>
              <div className="location-container-wrapper">
                <h4 className="first-item-h">Reg Fee:</h4>
                <p>KSH {tournament.fee}</p>    
              </div>
            </div> 
            <p>{tournament.description}</p>
            <div className="location-container-wrapper" style={{marginTop: 20}}>
              <h4 className="first-item-h">Participants:</h4>
              <p>{tournament.participants?.length || 0}</p>
            </div>
            <div className="prize-wrapper">
              <h2>Prize money:</h2>
              <h2>KSH {tournament.prize}</h2>
            </div>

            <div className="actions">
            <button 
              onClick={() => {
                if (buttonLoading) return;
                setButtonLoading(true);
                // Check if the user is already a participant
                if (tournament.participants?.includes(currentUserId)) {
                  Swal.fire({
                    icon: 'info',
                    title: 'Already Registered',
                    text: 'You have already joined this tournament!',
                    confirmButtonText: 'OK',
                  });
                  return; 
                }
                // Check if the tournament is free
                if (parseFloat(tournament.fee) === 0) {
                  // Directly add participant without payment
                  handleFreeTournament();
                } else {
                  // Payment flow for paid tournaments
                  handleFlutterPayment({
                    callback: (response) => {
                      if (response.status === 'successful') {
                        handlePaymentSuccess(response);
                      } else {
                        console.error("Payment failed or cancelled:", response);
                      }
                      setButtonLoading(false);
                      closePaymentModal(); 
                    },
                    onClose: () => {
                      console.log("Payment modal closed.");
                      setButtonLoading(false);
                    },
                  });
                }
              }}
              //   handleFlutterPayment({
              //     callback: (response) => {
              //       if (response.status === 'successful') {
              //         handlePaymentSuccess(response);
              //       } else {
              //         console.error("Payment failed or cancelled:", response);
              //       }
              //       closePaymentModal(); 
              //     },
              //     onClose: () => {
              //       console.log("Payment modal closed.");
              //     },
              //   });
              // }}
              className="join-tournaments-btn" disabled={buttonLoading}>
              {buttonLoading ? "Joining tournament..." : "Join Tournament"}
            </button>
              <Link className="join-tournaments-btn"   to={{pathname: `/fixtures/${tournament.tournamentURL}`, state: { tournamentId: tournament.tournamentId }}}>
                View Fixtures
              </Link>
            </div>
          </div> 

          <div className="tournament-img-container mobile-img">
            <img className="tournament-image" src={tournament.tournamentImage}/>
          </div>

        <div className="games__list mobile-img">
          <h4 className="featured-text span-text">Featured Games</h4>   
          <ul>
            <li>
                <iframe src="https://www.youtube.com/embed/tv7LfFeamsc" frameBorder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/GITzbGIiNKg" frameBorder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/XhP3Xh4LMA8" frameBorder="0" allowFullScreen></iframe>
            </li>
        </ul>
        </div>

          
        </div>  

        <div className="games__list desktop-img">
          <h4 className="featured-text span-text">Featured Games</h4>   
          <ul>
              <li>
                  <iframe src="https://www.youtube.com/embed/tv7LfFeamsc" frameborder="0" allowFullScreen></iframe>
              </li>
              <li>
                  <iframe src="https://www.youtube.com/embed/GITzbGIiNKg" frameborder="0" allowFullScreen></iframe>
              </li>
              <li>
                  <iframe src="https://www.youtube.com/embed/XhP3Xh4LMA8" frameborder="0" allowFullScreen></iframe>
              </li>
          </ul>
          <div className="final-btn">
            <div>
              <Link to="/tournaments">
              <button className="join-tournaments-btn">
                Live Tournaments
              </button>
              </Link>
            </div>

              <Link to="/create-tournament">
              <button  className="live-btn join-tournaments-btn">
                Create Tournament
              </button>
              </Link>

          </div>
        </div>  

        {/* <div className="games__list desktop-img">
        <h4 className="featured-text span-text">Featured Games</h4>   
        <ul>
            <li>
                <iframe src="https://www.youtube.com/embed/tv7LfFeamsc" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/GITzbGIiNKg" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/XhP3Xh4LMA8" frameborder="0" allowFullScreen></iframe>
            </li>
        </ul>
        </div> */}

        </div>
        
        <div className="tournament-img-container desktop-img">
          <img className="tournament-image" src={tournament.tournamentImage}/>
        </div>

        </div>
      </div>

  );
}

export default TournamentDetailsSection;